<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-28 19:21:03
-->
<template>
  <div class="greenchain ptn_r">
    <Content />
    
  </div>
</template>
<script>
import Content from './content'
export default {
  components: { Content },
  data() {
    return {
      flagNam: 18,
      tatleName: '供应商白名单'
    }
  },
  mounted() {
    this.flagNam = this.$route.meta.flagNam
    // this.add(this.flagNam)

    this.hub.$on('tatleNames', () => {
      // console.log(this.$route,12487);
      this.flagNam = this.$route.meta.flagNam
      // console.log(this.flagNam,this.$route.meta.flagNam);
      this.add(this.flagNam)
    })
  },
  methods: {

    onTarget() {
      // window.open("http://118.190.71.144:8099/IndustryRecord/Regulatory_Green.aspx", "_blank");
    },

    add(val) {
      this.flagNam = val
      // this.tatleName =
      switch (val) {
        case 6:
          this.tatleName = '供应商白名单';
          if (this.$route.name != 'SupplierSWhite') {

            this.$router.push({ name: 'SupplierSWhite' })
          }
          break;
        case 7:
          this.tatleName = '供应商绿名单';
          // window.open("https://greenlist.see.org.cn/Industry/GreenList.aspx?cmd=search&istenday=0&Page=1 ", "_blank");
          // if (this.$route.name != 'GreenList') {

          //   this.$router.push({ name: 'GreenList' })
          // }

          break;
        case 8:
          this.tatleName = '供应商黑名单';
          if (this.$route.name != 'BlackList') {

            this.$router.push({ name: 'BlackList' })
          }

          break;
        case 9:
          this.tatleName = '供应商检测结果公示';
          if (this.$route.name != 'Detection') {

            this.$router.push({ name: 'Detection' })
          }

          break;
        case 18:
          this.tatleName = '环境合规性供应商白名单检索';
          if (this.$route.name != 'Detection') {
            // this.onTarget()
            this.$router.push({ name: 'complianceSupplierswhite' })
          }


          break;


      }
    }
  }
}
</script>
<style lang="less" scoped>
// .w_auto {
//     width: 7.91667rem !important;
// }
.ptn_r {
  position: relative;
}

.flex {
  display: flex;
}

.greenchain {
  min-height: 500px;
  overflow: hidden;
}

.horizontal {
  position: absolute;
  left: -200px;
  width: 540px;
  margin-top: 246px;
  border-top: 1px solid #666;
}

.horizontal_2 {
  position: absolute;
  top: 240px;
  left: 460px;
  width: 40000px;
  border-top: 5px solid #8fa926;

  p {
    position: absolute;
    top: -50px;
    border-left: 4px solid #8fa926;
    margin-left: 2px;
    padding-left: 20px;
    font-size: 18px;
  }
}

.about {
  position: absolute;
  top: 120px;
  left: 40px;

  // width: 240px;
  div:nth-of-type(1) {
    font-size: 35px;
    font-weight: 700;
    color: #8fa926;
  }

  div:nth-of-type(2) {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}

.grnin {
  padding-top: 320px;
  margin-left: 60px;
  width: 280px;

  .grnin_1 {
    // justify-content: center;
    // padding-left: 20px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;

    p {
      width: 280px;
      text-align: center;
      //   left: -30px;
    }
  }
}

.green_color {
  color: #8fa926;
}

.green_bg {
  background: #8fa926;
  color: #fff;
}

.black_bg {
  color: black;
  font-weight: bold;
}

.TheContainer {
  padding: 247px 0 0 120px;
  flex: 1;

  .wh {
    width: 100%;
  }
}

.trend-container2 {
  position: relative;
  width: 100%;
  min-height: 700px;
  padding-bottom: 16px;
}</style>